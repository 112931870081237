$(document).ready(function () {
    Vue.use(VueMask.VueMaskPlugin);
    var app = new Vue({
        el: "#app",
        data: {
            isMenuOpened: false,
            isSearchShowed: false,
            form: {
                contacts: {
                    formName: "Контакты",
                    name: "",
                    email: "",
                    message: "",
                    checkbox: true,
                    attemptSubmit: false
                },
            }
        },
        computed: {},
        methods: {
            /**
             * Ввод телефона
             * @param form
             */
            enterPhone: function (form) {
                (form.phone.length == 0) ? form.phone = " " : form.phone
            },
            isImportantValid: function (val) {
                return val.length > 0;
            },
            /**
             * Проверка на валидность email
             * @param email
             * @return {boolean}
             */
            isEmailValid: function (email) {
                return /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/.test(email) && email.length > 0
            },
            /**
             * Валидация имени
             * @param name
             * @return {boolean}
             */
            isNameValid: function (name) {
                return !/^[A-Za-z0-9]+$/.test(name) && name.length >= 2
            },
            /**
             * Валидация телефона
             * @param phone
             * @return {boolean}
             */
            isPhoneValid: function (phone) {
                return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/.test(phone) && phone.length > 0
            },
            /**
             * Валидация
             * @param formName
             * @return {boolean}
             */
            validate: function (formName) {
                this.form[formName].attemptSubmit = true;
                var valid = "";

                switch (formName) {
                    case "contacts":
                            valid = this.isEmailValid(this.form[formName].email) && this.isNameValid(this.form[formName].name) && this.isImportantValid(this.form[formName].message) && this.form[formName].checkbox;
                        break;
                }

                if(valid) {
                    this.sendForm(this.form[formName]);
                } else {
                    return false;
                }
            },
            /**
             * Отправка формы
             * @param form
             */
            sendForm: function (form) {

                console.log(form);

                axios({
                    method: "post",
                    url: "/sender.php",
                    data: form,
                    config: { headers: {"Content-Type": "application/x-www-form-urlencoded" }}
                }).then(function (response) {
                    $.fancybox.open('<div class="message"><h2>Спасибо!</h2><p>Ваша заявка отправлена!</p></div>');
                }).catch(function (response) {});
            }
        }
    })

    $(".promo-slider").slick({
        prevArrow: '<div class="promo-slider__arrow promo-slider__arrow-prev"></div>',
        nextArrow: '<div class="promo-slider__arrow promo-slider__arrow-next"></div>',
        dots: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    arrows: false
                }
            }
        ]
    });
    $(".events-slider").slick({
        prevArrow: '<div class="events-slider__arrow events-slider__arrow-prev"></div>',
        nextArrow: '<div class="events-slider__arrow events-slider__arrow-next"></div>',
        slidesToShow: 4,
        dots: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    $(".founders-slider").slick({
        prevArrow: '<div class="founders-slider__arrow founders-slider__arrow-prev"></div>',
        nextArrow: '<div class="founders-slider__arrow founders-slider__arrow-next"></div>',
        slidesToShow: 5,
        dots: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    if($(".contacts__map").length) {
        ymaps.ready(init);
    }
    function init(){
        var myMap = new ymaps.Map("contacts__map", {
            center: [55.809054, 37.572351],
            zoom: 16
        });

        var myPlacemark = new ymaps.Placemark([55.809054, 37.572351], {});
        myMap.geoObjects.add(myPlacemark);
    }
});